import { useState } from "react";

import {
  Box,
  Button,
  Grid,
  TextField,
  Typography,
  styled,
} from "@mui/material";
// import Countdown from "react-countdown";
import { nanoid } from "nanoid";

import { LoadingButton } from "@mui/lab";
import { Helmet } from "react-helmet";

const Logo = styled(Box)(({ theme }) => ({
  position: "fixed",
  top: 0,
  left: 0,
  backgroundColor: "#2f80ed",
  backgroundImage: "url(/logo.png)",
  backgroundSize: "80%",
  backgroundRepeat: "no-repeat",
  backgroundPosition: "center center",
  width: 60,
  height: 60,
  borderBottomRightRadius: 24,
  zIndex: 1000,
}));

const PageContainer = styled(Box)(({ theme }) => ({
  textAlign: "center",
  height: "100%",
  display: "flex",
  flexDirection: "column",
}));

const EventRegister = styled(Box)(({ theme }) => ({
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
  flexGrow: 1,
  padding: 40,
}));

// const CountdownBox = styled(Box)(({ theme }) => ({
//   display: "flex",
//   alignItems: "center",
//   justifyContent: "center",
//   flexGrow: 1,
// }));

// const CountdownBoxItem = styled(Box)(({ theme }) => ({
//   margin: theme.spacing(0, 2),
//   "& > span": {
//     display: "block",
//     width: 50,

//     "&:first-child": {
//       fontSize: 36,
//       fontWeight: "bold",
//     },
//   },
// }));

function Trial({
  title,
  formTitle,
  successTitle,
  description,
  cover,
  url,
  programTitle,
  productId,
  productTitle,
  expiresAt,
}) {
  const [loading, setLoading] = useState(false);
  const [data, setData] = useState(null);
  const [error, setError] = useState(null);
  const [success, setSuccess] = useState(false);
  const [form, setForm] = useState({
    name: "",
    email: "",
    phone: "",
  });

  const handleChange = (e) => {
    const { name, value } = e.target;

    setForm((old) => ({
      ...old,
      [name]: value,
    }));
  };

  const parsePhone = (phone) => {
    if (!phone) return null;

    const noSpaces = phone
      .replaceAll(/\D/g, "")
      .replaceAll(/\s/g, "")
      .replaceAll(/\+/g, "")
      .replaceAll(/\(/g, "")
      .replaceAll(/\)/g, "");

    return `${noSpaces.startsWith("07") ? "4" : ""}${noSpaces}`;
  };

  const handleRegister = async (e) => {
    e.preventDefault();

    if (!form.email && !form.name && !form.phone) {
      setError("Trebuie să completezi toate câmpurile");
      return;
    }

    setError(null);
    setLoading(true);

    const password = nanoid(10);
    const body = {
      full_name: form.name,
      username: form.email,
      password: password,
      phone: parsePhone(form.phone),
      autologin_url: `https://restartix.ro/conectare/?u=${encodeURIComponent(
        form.email
      )}&a=${encodeURIComponent(password)}`,
      productId,
      productTitle,
      expiresAt,
    };

    try {
      const res = await fetch(
        "https://hook.eu1.make.com/ernk5rkqp2ih8mv5eeb5smgeqj577iaj",
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify(body),
        }
      );

      if (!res.ok) {
        throw new Error(`Response status: ${res.status}`);
      }

      const json = await res.json();

      setSuccess(true);
      setData(json);
      setLoading(false);
    } catch (err) {
      setError("A apărut o eroare, încearcă din nou sau contactează-ne");
      setSuccess(false);
      setLoading(false);
    }
  };

  // const countdownRenderer = ({ days, hours, minutes, seconds, completed }) => {
  //   if (completed) {
  //     // Render a completed state
  //     return null;
  //   } else {
  //     // Render a countdown
  //     return (
  //       <CountdownBox>
  //         <CountdownBoxItem>
  //           <span>{days}</span>
  //           <span>{days === 1 ? "zi" : "zile"}</span>
  //         </CountdownBoxItem>
  //         <CountdownBoxItem>
  //           <span>{hours}</span>
  //           <span>{hours === 1 ? "oră" : "ore"}</span>
  //         </CountdownBoxItem>
  //         <CountdownBoxItem>
  //           <span>{minutes}</span>
  //           <span>{minutes === 1 ? "minut" : "minute"}</span>
  //         </CountdownBoxItem>
  //         <CountdownBoxItem>
  //           <span>{seconds}</span>
  //           <span>{seconds === 1 ? "secundă" : "secunde"}</span>
  //         </CountdownBoxItem>
  //       </CountdownBox>
  //     );
  //   }
  // };

  return (
    <PageContainer>
      <Helmet>
        <title>{title}</title>
        <meta name="description" content={description} />
      </Helmet>

      <Logo />

      <EventRegister>
        <Box>
          <Box sx={{ maxWidth: 200, margin: "0 auto" }}>
            <img src={cover} alt={title} />
          </Box>
          {/* <Box
              sx={{ mt: 2, p: 2, backgroundColor: "#ffffe8", borderRadius: 1 }}
            >
              <Typography>Ofertă valabilă încă</Typography>
              <Countdown
                date={new Date("2024-10-14")}
                renderer={countdownRenderer}
              />
            </Box> */}

          {success ? (
            <Box>
              <Box sx={{ my: 4 }}>
                <Typography variant="h4">{successTitle}</Typography>
                <Typography variant="h6">{programTitle}</Typography>
              </Box>

              <Button
                href={
                  data.autologin_url
                    ? `${data.autologin_url}&redirect=${url}`
                    : url
                }
                fullWidth
                variant="contained"
                color="primary"
                size="large"
                type="submit"
              >
                Accesează platforma
              </Button>
            </Box>
          ) : (
            <>
              <Box sx={{ my: 4 }}>
                <Typography variant="h4">{formTitle}</Typography>
                <Typography variant="h6">{programTitle}</Typography>
              </Box>
              <form onSubmit={handleRegister}>
                <Grid container spacing={2}>
                  <Grid item xs={12}>
                    <Box sx={{ maxWidth: 500, margin: "0 auto" }}>
                      <TextField
                        fullWidth
                        label="Numele tău"
                        name="name"
                        value={form.name}
                        onChange={handleChange}
                      />
                    </Box>
                  </Grid>
                  <Grid item xs={12}>
                    <Box sx={{ maxWidth: 500, margin: "0 auto" }}>
                      <TextField
                        fullWidth
                        label="Emailul tău"
                        name="email"
                        value={form.email}
                        onChange={handleChange}
                      />
                    </Box>
                  </Grid>
                  <Grid item xs={12}>
                    <Box sx={{ maxWidth: 500, margin: "0 auto" }}>
                      <TextField
                        fullWidth
                        label="Telefon"
                        name="phone"
                        value={form.phone}
                        onChange={handleChange}
                      />
                    </Box>
                  </Grid>
                  <Grid item xs={12}>
                    <Box
                      sx={{
                        textAlign: "left",
                        maxWidth: 500,
                        margin: "0 auto",
                      }}
                    >
                      <Typography>
                        Apăsând butonul "Vreau acces gratuit" înseamnă că:
                      </Typography>
                      <Typography sx={{ mt: 1 }}>
                        <span style={{ fontWeight: "bold", color: "#48c948" }}>
                          ✓
                        </span>{" "}
                        Ești de acord cu{" "}
                        <a
                          href="https://restartix.com/termeni-si-conditii"
                          target="_blank"
                          rel="noreferrer"
                        >
                          termenii și condițiile
                        </a>{" "}
                        RestartiX.
                      </Typography>
                      <Typography>
                        <span style={{ fontWeight: "bold", color: "#48c948" }}>
                          ✓
                        </span>{" "}
                        Ești de acord cu{" "}
                        <a
                          href="https://restartix.com/politica-de-confidentialitate"
                          target="_blank"
                          rel="noreferrer"
                        >
                          prelucrarea datelor
                        </a>{" "}
                        în scopul îmbunătățirii platformei RestartiX.
                      </Typography>
                      <Typography>
                        <span style={{ fontWeight: "bold", color: "#48c948" }}>
                          ✓
                        </span>{" "}
                        Ești de acord să primești pe email materiale informative
                        și oferte speciale RestartiX.
                      </Typography>
                      <Typography>
                        <span style={{ fontWeight: "bold", color: "#48c948" }}>
                          ✓
                        </span>{" "}
                        Ești de acord să primești pe telefon notificări legate
                        de contul tău RestartiX.
                      </Typography>
                    </Box>
                  </Grid>
                  {error && (
                    <Grid item xs={12}>
                      <Box
                        sx={{
                          mt: 2,
                          p: 2,
                          backgroundColor: "#ffffe8",
                          color: "#a41313",
                          borderRadius: 1,
                        }}
                      >
                        <Typography>{error}</Typography>
                      </Box>
                    </Grid>
                  )}
                  <Grid item xs={12}>
                    <Box sx={{ maxWidth: 500, margin: "0 auto" }}>
                      <LoadingButton
                        fullWidth
                        variant="contained"
                        color="primary"
                        size="large"
                        type="submit"
                        disabled={loading}
                        loading={loading}
                      >
                        Vreau acces gratuit
                      </LoadingButton>
                    </Box>
                  </Grid>
                </Grid>
              </form>
            </>
          )}
        </Box>
      </EventRegister>
    </PageContainer>
  );
}

export default Trial;
