import React from "react";
import ReactDOM from "react-dom/client";
import { createBrowserRouter, RouterProvider } from "react-router-dom";
import { CssBaseline, ThemeProvider } from "@mui/material";
import { add } from "date-fns";
import "./index.css";
import Event from "./Event";
// import Home from "./Home";
import theme from "./core/theme/light";
import FirebaseProvider from "./core/services/firebase/FirebaseProvider";
import Trial from "./Trial";
// import reportWebVitals from "./reportWebVitals";

const router = createBrowserRouter([
  {
    path: "/",
    element: <Event title="Event" />,
  },
  {
    path: "/30-zile-gratuit",
    element: (
      <Trial
        title="RestartiX - 30 zile Acces Gratuit"
        formTitle="30 zile Acces Gratuit la"
        successTitle="Ai primit 30 de zile acces gratiut la"
        description="30 zile Acces Gratuit la Provocarea de 5 zile - Scapă de Durerile Lombare"
        cover="https://restartix.ro/wp-content/uploads/2024/03/lombar-evergreen-1200x1200.jpg"
        url="https://restartix.ro/programe/provocarea-de-5-zile-scapa-de-durerile-lombare-unic/"
        programTitle="Durerile Lombare - Provocarea de 5 zile"
        productId="85"
        productTitle="Package - Provocarea de 5 Zile - Scapă de Durerile Lombare"
        expiresAt={add(new Date(), { days: 30 }).toISOString()}
      />
    ),
  },
  {
    path: "/tehnici-relaxare-acces-pe-viata",
    element: (
      <Trial
        title="RestartiX - Acces pe Viață Gratuit"
        formTitle="Acces pe Viață Gratuit la"
        successTitle="Ai primit gratiut acces pe viață la"
        description="Acces pe Viață Gratuit la 2 Tehnici de Relaxare Ghidată"
        cover="https://restartix.ro/wp-content/uploads/2021/01/TEhnica-de-relaxare-Jacobson-1200x1200.jpg"
        url="https://restartix.ro/programe/tehnica-de-relaxare-jacobson/"
        programTitle="2 Tehnici de Relaxare Ghidată"
        productId="49"
        productTitle="Package - 2 Tehnici de Rexalare Ghidată"
        expiresAt={add(new Date(), { years: 100 }).toISOString()}
      />
    ),
  },
  {
    path: "/workshop-acces-pe-viata",
    element: (
      <Trial
        title="RestartiX - Acces pe Viață Gratuit"
        formTitle="Acces pe Viață Gratuit la"
        successTitle="Ai primit gratiut acces pe viață la"
        description="Acces pe Viață Gratuit la 2 Workshop-uri Tranformaționale"
        cover="https://restartix.ro/wp-content/uploads/2024/10/Workshop-Transformational-2023-Cover.jpg"
        url="https://restartix.ro/programe/noi-orizonturi-viziune-si-actiune-pentru-2024/"
        programTitle="2 Workshop-uri Tranformaționale"
        productId="95"
        productTitle="Package - Workshop Transformațional"
        expiresAt={add(new Date(), { years: 100 }).toISOString()}
      />
    ),
  },
  {
    path: "/mastermind-acces-pe-viata",
    element: (
      <Trial
        title="RestartiX - Acces pe Viață Gratuit"
        formTitle="Acces pe Viață Gratuit la"
        successTitle="Ai primit gratiut acces pe viață la"
        description="Acces pe Viață Gratuit la Mastermind Classes"
        cover="https://restartix.ro/wp-content/uploads/2020/10/Mastermind-Classes-Cover.jpg"
        url="https://restartix.ro/programe/mastermind-classes/"
        programTitle="Mastermind Classes"
        productId="51"
        productTitle="Package - Mastermind Classes"
        expiresAt={add(new Date(), { years: 100 }).toISOString()}
      />
    ),
  },
  {
    path: "/:alias",
    element: <Event title="Event Single" />,
  },
]);

const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
  <React.StrictMode>
    <FirebaseProvider>
      <ThemeProvider theme={theme}>
        <CssBaseline />

        <RouterProvider router={router} />
      </ThemeProvider>
    </FirebaseProvider>
  </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
// reportWebVitals();
